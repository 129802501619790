<template>
   <section class="self_sec reste-pas">
         <div class="container-fluid">
            <div class="row">
               <div class="col-md-6">
                  <div class="login_signup_form reset-pwd py_60 mt_10">
                    <FlashMessage :message="message" :error="error"  v-if="checkType" />
                     <h2 class="text_black font_size_36 mb_40">Reset Password</h2>
                     
                     <form @submit.prevent="resetPassword">
                        <div class="form-group">
                           <label class="font_size_24 mb_20 font_bold">Email</label>
                           <input type="email" placeholder="Email" class="form-control" v-model="email">
                           <span v-if="error && error.email" class="error" style="color:red">{{error.email[0]}}</span>
                        </div>
                        <div class="form-group">
                           <label class="font_size_24 mb_20 font_bold">Password</label>
                           <input type="password" placeholder="Password" class="form-control" v-model="password">
                           <span v-if="error && error.password" class="error" style="color:red">{{error.password[0]}}</span> 
                        </div>
                        <div class="form-group">
                           <label class="font_size_24 mb_20 font_bold">Confirm Password</label>
                           <input type="password" placeholder="Password" class="form-control" v-model="passwordConfirm">
                           <span v-if="error && error.password_confirmation" class="error" style="color:red">{{error.password_confirmation[0]}}</span>
                        </div>
                        <div class="mt_68 d-flex justify-content-between align-items-center btn_link_box">
                           <div>
                              <button class="btn btn_primary">Reset Password</button>
                           </div>
                        </div>
                     </form>
                  </div>
               </div>
               <div class="col-md-6 p-0">
                  <div class="self_img">
                     <img src="../assets/images/schedule_img.png" alt="img">
                  </div>
               </div>
            </div>
         </div>
      </section>
</template>

<script>
import { getError } from "@/utils/helpers";
import AuthService from "@/services/AuthService";
import FlashMessage from "@/components/FlashMessage";
export default {
  name: "ResetPassword",
  components: {
    FlashMessage,
  },
  data() {
    return {
      email: null,
      password: null,
      passwordConfirm: null,
      error: null,
      message: null,
      checkType:false,
    };
  },
  methods: {
    resetPassword() {
      this.error = null;
      this.message = null;
      this.checkType = false;
      const payload = {
        email: this.email,
        password: this.password,
        password_confirmation: this.passwordConfirm,
        token: this.$route.query.token,
      };
      AuthService.resetPassword(payload)
        .then(() => {
            this.checkType = true;
            this.message = "Password reset.";
            setTimeout(() => {
                this.$router.push('/login');    
            }, 1000);        
        }
        )
        .catch((error) => {
          this.error = getError(error);
          const errType = Object.prototype.toString.call(getError(error)).slice(8, -1).toLowerCase();
          if (errType === "string") {
            this.checkType = true;
          }
        });
    },
  },
};
</script>